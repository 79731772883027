import TopBar from "./Authed/Sections/Header/TopBar";
import Menu from "./Authed/Sections/Header/Menu";
import { config, chainMappingRPCs } from "../config";
import StatBoxesHelper from "./StatBoxesHelper";
import { useAuth } from "../Hooks/useAuthentication";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { LineChartHelper } from "./ChartHelper";
import React from "react";
import { useMemo } from "react";
import { timeAgo } from "../Helpers/epochTimeAgo";
import { formatDateTimeForDisplay } from "../Helpers/epochToString";
import { getStyledUsername } from "../Hooks/useXPSystem";
import { ChainLogo } from "../Helpers/getChainLogo";
import { truncateText } from "../Helpers/truncateText";

const Countdown = ({ targetDate }) => {
	const [countdown, setCountdown] = useState("");

	useEffect(() => {
		const timer = setInterval(() => {
			const now = new Date();
			const targetDateObject = new Date(targetDate * 1000); // Convert epoch to Date object
			const difference = targetDateObject - now;
			const timeLeft = formatTimeLeft(difference);
			setCountdown(timeLeft);
		}, 1000);

		return () => clearInterval(timer);
	}, [targetDate]);

	const formatTimeLeft = (difference) => {
		if (difference > 0) {
			const weeks = Math.floor(difference / (1000 * 60 * 60 * 24 * 7));
			const days = Math.floor((difference / (1000 * 60 * 60 * 24)) % 7);
			const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
			const minutes = Math.floor((difference / 1000 / 60) % 60);
			const seconds = Math.floor((difference / 1000) % 60);
			return `${weeks}w ${days}d ${hours}h ${minutes}m ${seconds}s`;
		}
		return "Time is up!";
	};

	const formattedDate = new Date(targetDate * 1000).toLocaleString(); // Format the date

	return (
		<div className="card h-100 mb-4">
			<div className="card-header text-white bg-primary text-center">
				<h4 className="card-title mb-0">Reward Countdown to Next Payout</h4>
			</div>
			<div className="card-body text-center">
				<div className="h1 mb-3">{countdown}</div>
				<h5 className="mb-3">{formattedDate}</h5> {/* Display the formatted date */}
				<Link
					to="/panel/admin/manage-reward-pot"
					className="btn btn-sm btn-primary rounded"
				>
					<i className="fa-solid fa-pencil"></i> Manage Pot
				</Link>
			</div>
		</div>
	);
};

export default function AdminDashboard() {
	const auth = useAuth();

	const [showBlurred, setShowBlurred] = useState(true);

	const [stats, setStats] = useState({
		users: 0,
		domains: 0,
		tickets: 0,
		news: 0,
		designs: 0,
		blacklists: 0,
		connects: 0,
		hits: 0,
		usersOnline: 0,
		onlineUsers: [],
	});

	const [chartDatas, setChartDatas] = useState({
		domains: [],
		users: [],
		chains: [],
		modules: [],
	});

	const [tableDatas, setTableDatas] = useState({
		rewards: [],
		tickets: [],
	});

	const [countdown, setCountdown] = useState(null);

	// Memoize chartDatas to prevent unnecessary re-renders
	const memoizedChartDatas = useMemo(() => chartDatas, [chartDatas]);

	// For showing the online users modal
	const [showOnlineUsers, setShowOnlineUsers] = useState(false);

	const [nextRewardTime, setNextRewardTime] = useState(null);

	// ------------------------------------------------------------

	const fetchStats = useCallback(async () => {
		const response = await fetch(`${config.apiUrl}/fpanel/admin/dashStats`, {
			method: "POST",
			headers: {
				"Authorization": `Bearer ${auth.token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				token: auth.token,
				username: auth.username,
			}),
		});
		const data = await response.json();
		if (data.success) {
			if (data.data) {
				setStats((prevStats) => ({
					...prevStats,
					...data.data,
				}));
			}
			if (data.charts) {
				setChartDatas((prevChartDatas) => {
					// Only update if the data has actually changed
					if (JSON.stringify(prevChartDatas) !== JSON.stringify(data.charts)) {
						return {
							...prevChartDatas,
							...data.charts,
						};
					}
					return prevChartDatas;
				});
			}
			if (data.tables) {
				setTableDatas((prevTableDatas) => ({
					...prevTableDatas,
					...data.tables,
				}));
			}
			if (data.countdown) {
				setCountdown(data.countdown.next_payout_time);
				setNextRewardTime(data.countdown.next_payout_time);
			}
		}
	}, [auth.token]);

	useEffect(() => {
		if (auth.loading) return;
		fetchStats();
	}, [auth.loading, fetchStats]);

	useEffect(() => {
		console.log(`auth`, auth);
	}, [auth]);

	useEffect(() => {
		var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
		var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new window.bootstrap.Tooltip(tooltipTriggerEl);
		});
	}, []);

	// ------------------------------------------------------------

	const makeUserLevelBadge = (level) => {
		switch (level) {
			case "user":
				return <span className="badge bg-primary">User</span>;
			case "admin":
				return <span className="badge bg-danger">Admin</span>;
			default:
				return <span className="badge bg-warning">{level}</span>;
		}
	};

	const makeExplorerLink = (address, isTx = false) => {
		var chainID = 42161;
		var matchingChain = chainMappingRPCs[chainID]?.explorerUrl;
		if (matchingChain) {
			return `${matchingChain}/${isTx ? "tx" : "address"}/${address}`;
		}
		return false;
	};

	// ------------------------------------------------------------

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar
					showBlurred={showBlurred}
					setShowBlurred={setShowBlurred}
					showToggle={true}
				/>
				<main className={`content ${showBlurred ? "blurred" : ""}`}>
					<div className="container-fluid p-0">
						<div className="row mb-2 mb-xl-3">
							<div className="col d-flex justify-content-between align-items-center">
								<h3>
									<strong>{config.siteName}</strong> Dashboard
								</h3>
							</div>
						</div>

						<div className="row mb-4 gap-md-0 gap-2">
							<div className="col-md-3">
								<div className="card h-100 mb-2">
									<div className="card-header bg-gradient-to-r from-cyan-500 to-blue-500 text-white">
										<h4 className="card-title mb-0">Welcome, {auth.username} 👋</h4>
									</div>
									<div className="card-body">
										<p
											className="text-dark mb-1"
											data-bs-toggle="tooltip"
											title={auth.dateJoined ? formatDateTimeForDisplay(auth.dateJoined) : "N/A"}
										>
											You've been registered for <strong>{auth.dateJoined ? timeAgo(auth.dateJoined) : "N/A"}</strong>
										</p>
										<p className="text-dark mb-1">
											Your user level is <strong>{makeUserLevelBadge(auth.userLevel)}</strong>
										</p>
										<p className="text-dark mb-2">
											Your rank is <strong>{getStyledUsername(auth.level, auth.level)}</strong>
										</p>
										<Link
											to="/panel/admin/my-account"
											className="btn btn-sm btn-primary rounded"
										>
											<i className="fa-solid fa-pencil"></i> Manage Account
										</Link>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="card h-100 mb-2">
									<div className="card-header bg-gradient-to-r from-cyan-500 to-blue-500 text-white">
										<h4 className="card-title mb-0">Online Users</h4>
									</div>
									<div className="card-body">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<div>
												<h2 class="mb-0 fw-bold">{stats.usersOnline}</h2>
											</div>
											<div
												class="icon-circle bg-primary d-flex align-items-center justify-content-center"
												style={{ width: "60px", height: "60px", borderRadius: "50%" }}
											>
												<i class="fas fa-users fa-2x text-light"></i>
											</div>
										</div>
										<button
											className="btn btn-sm btn-primary rounded"
											onClick={() => setShowOnlineUsers(true)}
										>
											<i className="fa-solid fa-eye"></i> View Online Users
										</button>
									</div>
								</div>
							</div>
							<div className="col-md-5">
								<Countdown targetDate={nextRewardTime} />
							</div>
						</div>

						<div className="row">
							<div className="col-6 col-md-6 col-xl-6">
								<div className="card">
									<div className="card-header d-flex justify-content-between align-items-center">
										<h4 className="card-title mb-0">Top 5 Domains by Revenue</h4>
										<Link
											to="/panel/admin/manage-domains/view"
											className="btn btn-sm btn-primary rounded"
										>
											View All Domains
										</Link>
									</div>
									<div className="card-body">
										<LineChartHelper
											labelType="domain"
											chartData={memoizedChartDatas.domains}
										/>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-6 col-xl-6">
								<div className="card">
									<div className="card-header d-flex justify-content-between align-items-center">
										<h4 className="card-title mb-0">Top 5 Users by Revenue</h4>
										<Link
											to="/panel/admin/manage-users/view"
											className="btn btn-sm btn-primary rounded"
										>
											View All Users
										</Link>
									</div>
									<div className="card-body">
										<LineChartHelper
											labelType="username"
											chartData={memoizedChartDatas.users}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-md-12 col-xl-12">
								<div className="card">
									<div className="card-header d-flex justify-content-between align-items-center">
										<h4 className="card-title mb-0">Top 5 Designs by Revenue</h4>
										<Link
											to="/panel/admin/manage-designs/view"
											className="btn btn-sm btn-primary rounded"
										>
											View All Designs
										</Link>
									</div>
									<div className="card-body">
										<LineChartHelper
											labelType="design"
											chartData={memoizedChartDatas.designs}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-6 col-md-6 col-xl-6">
								<div className="card">
									<div className="card-header d-flex justify-content-between align-items-center">
										<h4 className="card-title mb-0">Top 5 Chains by Revenue</h4>
									</div>
									<div className="card-body">
										<LineChartHelper
											labelType="chain"
											chartData={memoizedChartDatas.chains}
										/>
									</div>
								</div>
							</div>
							<div className="col-6 col-md-6 col-xl-6">
								<div className="card">
									<div className="card-header d-flex justify-content-between align-items-center">
										<h4 className="card-title mb-0">Top 5 Modules by Revenue</h4>
									</div>
									<div className="card-body">
										<LineChartHelper
											labelType="module"
											chartData={memoizedChartDatas.modules}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<StatBoxesHelper
								title="Users"
								cardSize="col-md-2 col-xl-2"
								icon="user"
								value={stats.users}
								showButton={true}
								buttonText="View"
								buttonLink="/panel/admin/manage-users/view"
								buttonWidth="w-100"
								buttonDisplayBlock={true}
							/>
							<StatBoxesHelper
								title="Domains"
								cardSize="col-md-2 col-xl-2"
								icon="layout"
								value={stats.domains}
								showButton={true}
								buttonText="View"
								buttonLink="/panel/admin/manage-domains/view"
								buttonWidth="w-100"
								buttonDisplayBlock={true}
							/>
							<StatBoxesHelper
								title="Tickets"
								cardSize="col-md-2 col-xl-2"
								icon="help-circle"
								value={stats.tickets}
								showButton={true}
								buttonText="View"
								buttonLink="/panel/admin/manage-tickets/view"
								buttonWidth="w-100"
								buttonDisplayBlock={true}
							/>
							<StatBoxesHelper
								title="News"
								cardSize="col-md-2 col-xl-2"
								icon="message-circle"
								value={stats.news}
								showButton={true}
								buttonText="View"
								buttonLink="/panel/admin/manage-news/view"
								buttonWidth="w-100"
								buttonDisplayBlock={true}
							/>
							<StatBoxesHelper
								title="Designs"
								cardSize="col-md-2 col-xl-2"
								icon="edit-2"
								value={stats.designs}
								showButton={true}
								buttonText="View"
								buttonLink="/panel/admin/manage-designs/view"
								buttonWidth="w-100"
								buttonDisplayBlock={true}
							/>
							<StatBoxesHelper
								title="Blacklists"
								cardSize="col-md-2 col-xl-2"
								icon="slash"
								value={stats.blacklists}
								showButton={true}
								buttonText="View"
								buttonLink="/panel/admin/manage-blacklists/view"
								buttonWidth="w-100"
								buttonDisplayBlock={true}
							/>
						</div>

						<div className="row">
							<StatBoxesHelper
								title="Connects"
								cardSize="col-md-6 col-xl-6"
								icon="share-2"
								value={stats.connects}
							/>
							<StatBoxesHelper
								title="Hits"
								cardSize="col-md-6 col-xl-6"
								icon="chevrons-down"
								value={stats.hits}
							/>
						</div>

						<div className="row">
							<div className="col-7">
								<div className="card">
									<div className="card-header d-flex justify-content-between align-items-center">
										<h4 className="card-title mb-0">Top 30 recent reward pot payouts</h4>
										<Link
											to="/panel/admin/manage-reward-pot"
											className="btn btn-sm btn-primary rounded"
										>
											View All
										</Link>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>ID</th>
														<th>Position</th>
														<th>Username</th>
														<th>Amount</th>
														<th>Date</th>
														<th>Hash</th>
													</tr>
												</thead>
												<tbody>
													{tableDatas.rewards && tableDatas.rewards.length > 0 ? (
														tableDatas.rewards.map((reward, index) => (
															<tr key={index}>
																<td>{reward.id}</td>
																<td>
																	{reward.winner_position}/3{" "}
																	{reward.winner_position === 1 ? (
																		<i className="fa-solid fa-trophy text-warning"></i>
																	) : reward.winner_position === 2 ? (
																		<i className="fa-solid fa-trophy text-info"></i>
																	) : (
																		<i className="fa-solid fa-trophy text-danger"></i>
																	)}
																</td>
																<td>
																	<Link
																		to={`/panel/admin/manage-users/${reward.user_id}`}
																		className="text-dark text-decoration-none"
																	>
																		{reward.username ? reward.username : "N/A"}
																	</Link>
																	<Link
																		to={`/panel/admin/manage-users/${reward.user_id}/analytics`}
																		className="text-dark text-decoration-none ms-2"
																	>
																		<i className="fa-solid fa-chart-bar"></i>
																	</Link>
																	<Link
																		to={`/panel/admin/manage-users/${reward.user_id}/withdraw`}
																		className="text-dark text-decoration-none ms-2"
																	>
																		<i className="fa-solid fa-money-bill-alt"></i>
																	</Link>
																</td>
																<td>
																	{reward.amount} <ChainLogo chainID={1} />
																</td>
																<td>{timeAgo(reward.datetime)}</td>
																<td>
																	{reward.tx_hash ? (
																		<a
																			href={makeExplorerLink(reward.tx_hash, true)}
																			className="text-dark text-decoration-none"
																			target="_blank"
																			rel="noopener noreferrer"
																		>
																			<i className="fa-solid fa-external-link"></i>
																		</a>
																	) : (
																		"N/A"
																	)}
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="6">No data available</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div className="col-5">
								<div className="card">
									<div className="card-header d-flex justify-content-between align-items-center">
										<h4 className="card-title mb-0">Open Support Tickets ({tableDatas.tickets.length || 0})</h4>
										<Link
											to="/panel/admin/manage-tickets/view"
											className="btn btn-sm btn-primary rounded"
										>
											View All
										</Link>
									</div>
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>ID</th>
														<th>Username</th>
														<th>Status</th>
														<th>Created</th>
														<th>Updated</th>
														<th>Subject</th>
														<th>Message</th>
														<th>Manage</th>
													</tr>
												</thead>
												<tbody>
													{tableDatas.tickets && tableDatas.tickets.length > 0 ? (
														tableDatas.tickets.map((reward, index) => (
															<tr key={index}>
																<td>{reward.id}</td>
																<td>
																	{reward.username ? (
																		<Link
																			to={`/panel/admin/manage-users/${reward.user_id}`}
																			className="text-dark text-decoration-none"
																		>
																			{reward.username} <i className="fa-solid fa-external-link"></i>
																		</Link>
																	) : (
																		"N/A"
																	)}
																</td>
																<td>{reward.ticketStatus}</td>
																<td style={{ minWidth: "120px" }}>{reward.dateCreated ? timeAgo(reward.dateCreated) : "N/A"}</td>
																<td style={{ minWidth: "120px" }}>{reward.lastReplyDate ? timeAgo(reward.lastReplyDate) : "N/A"}</td>
																<td style={{ minWidth: "120px" }}>{reward.subject ? truncateText(reward.subject, 50) : "N/A"}</td>
																<td style={{ minWidth: "120px" }}>{reward.message ? truncateText(reward.message, 50) : "N/A"}</td>
																<td style={{ minWidth: "100px" }}>
																	<Link
																		to={`/panel/admin/manage-tickets/view/${reward.id}`}
																		className="btn btn-sm btn-primary rounded"
																	>
																		<i className="fa-solid fa-eye"></i>
																	</Link>
																	<Link
																		to={`/panel/admin/manage-tickets/edit/${reward.id}`}
																		className="btn btn-sm btn-primary rounded ms-2"
																	>
																		<i className="fa-solid fa-pencil"></i>
																	</Link>
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="6">No data available</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>

			{showOnlineUsers && (
				<>
					<div
						className="modal fadeUp show"
						id="centeredModalPrimary"
						tabIndex={-1}
						style={{ display: "block" }}
						aria-modal="true"
						role="dialog"
					>
						<div
							className="modal-dialog modal-dialog-centered"
							role="document"
						>
							<div className="modal-content">
								<div className="modal-header">
									<h5
										className="modal-title"
										style={{ textTransform: "capitalize" }}
									>
										Online Users
									</h5>
									<button
										type="button"
										className="btn-close"
										onClick={() => setShowOnlineUsers(false)}
										aria-label="Close"
									/>
								</div>
								<div className="modal-body m-0 p-0">
									<table className="table">
										<thead>
											<tr>
												<th className="text-center">#</th>
												<th className="text-center">Avatar</th>
												<th className="text-center">User</th>
												<th className="text-center">Level</th>
												<th className="text-center">Actions</th>
											</tr>
										</thead>
										<tbody>
											{stats.onlineUsers.map((user, index) => (
												<tr key={index}>
													<td style={{ textAlign: "center" }}>{index + 1}</td>
													<td style={{ textAlign: "center" }}>
														<img
															src={user.avatarURL && user.avatarURL.length > 0 ? user.avatarURL : "/img/newLogoLight.png"}
															alt="avatar"
															style={{ width: "30px", height: "30px", borderRadius: "50%" }}
														/>
													</td>
													<td style={{ textAlign: "center" }}>
														<span style={{ display: "inline-flex", alignItems: "center" }}>
															<span
																style={{ width: "10px", height: "10px", backgroundColor: "#24d921", borderRadius: "50%", display: "inline-block", marginRight: "8px" }}
															></span>
															{user.username}
														</span>
													</td>
													<td style={{ textAlign: "center" }}>{makeUserLevelBadge(user.user_level)}</td>
													<td style={{ textAlign: "center" }}>
														<Link
															to={`/panel/admin/manage-users/${user.id}`}
															rel="noopener noreferrer"
															className="btn btn-sm btn-primary rounded"
															title="Edit User"
														>
															<i className="fa-solid fa-pencil"></i>
														</Link>
														<Link
															to={`/panel/admin/manage-users/${user.id}/analytics`}
															rel="noopener noreferrer"
															className="btn btn-sm btn-info ms-2 rounded"
															title="User Analytics"
														>
															<i className="fa-solid fa-chart-bar"></i>
														</Link>
														<Link
															to={`/panel/admin/manage-users/${user.id}/withdraw`}
															rel="noopener noreferrer"
															className="btn btn-sm btn-warning ms-2 rounded"
															title="User Balances"
														>
															<i className="fa-solid fa-money-bill-alt"></i>
														</Link>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-primary"
										onClick={() => setShowOnlineUsers(false)}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</>
			)}
		</div>
	);
}
