import TopBar from "../Sections/Header/TopBar";
import Menu from "../Sections/Header/Menu";
import { useState, useEffect } from "react";
import { useAuth } from "../../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { splitNameservers } from "../../../Helpers/splitNameservers";
import useDomainIcons from "../../../Hooks/useDomainIcons";
import { getDefaultIcon, placeholderIcon } from "../../../Helpers/getDomainIcon";
import { config } from "../../../config";

export default function Tools() {
	const {username, token} = useAuth();

    // Store alerts for each tool section in format:
    /*
        {
            tool: 'ip-to-geolocation',
            type: 'danger',
            message: 'An error occurred while fetching the IP to geolocation.',
            duration: 5000
        }
    */
    const [alert, setAlert] = useState([]);
    const [results, setResults] = useState({});

    const validateIP = (ip) => {
        return /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ip);
    }

    // IP to geolocation
    const getIPToGeolocation = async (ip) => {
        if(!validateIP(ip)) {
            setAlert((prev) => {
                const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-to-geolocation'); // Remove existing alert for the same tool
                return [
                    ...updatedAlerts,
                    {
                        type: 'danger',
                        tool: 'ip-to-geolocation',
                        message: 'Invalid IP address.',
                        duration: 5000
                    }
                ];
            });
            // Reset the results for ip-to-geolocation
            setResults((prev) => {
                const updatedResults = { ...prev };
                updatedResults['ip-to-geolocation'] = null;
                return updatedResults;
            });
            return;
        }
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/ipToGeo`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({username, token, ip})
            });
            if(!response.ok) {
                throw new Error('Failed to fetch IP to geolocation.');
            }
            const data = await response.json();
            var value = data.data;
            if(value) {
                setAlert((prev) => {
                    const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-to-geolocation'); // Remove existing alert for the same tool
                    return [
                        ...updatedAlerts,
                        {
                            type: 'success',
                            tool: 'ip-to-geolocation',
                            message: 'IP to geolocation fetched successfully.',
                            duration: 5000
                        }
                    ];
                });
                setResults((prev) => {
                    const updatedResults = { ...prev };
                    updatedResults['ip-to-geolocation'] = value; // Store result with tool ID
                    return updatedResults;
                });
            } else {
                setAlert((prev) => {
                    const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-to-geolocation'); // Remove existing alert for the same tool
                    return [
                        ...updatedAlerts,
                        {
                            type: 'danger',
                            tool: 'ip-to-geolocation',
                            message: 'An error occurred while fetching the IP to geolocation.',
                            duration: 5000
                        }
                    ];
                });
            }
            return value;
        } catch (error) {
            console.error(error);
            setAlert((prev) => {
                const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-to-geolocation'); // Remove existing alert for the same tool
                return [
                    ...updatedAlerts,
                    {
                        type: 'danger',
                        tool: 'ip-to-geolocation',
                        message: error.message || 'An error occurred while fetching the IP to geolocation.',
                        duration: 5000
                    }
                ];
            });
        }
    }

    // IP score
    const ipToScore = async (ip) => {
        if(!validateIP(ip)) {
            setAlert((prev) => {
                const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-to-geolocation'); // Remove existing alert for the same tool
                return [
                    ...updatedAlerts,
                    {
                        type: 'danger',
                        tool: 'ip-to-score',
                        message: 'Invalid IP address.',
                        duration: 5000
                    }
                ];
            });
            // Reset the results for ip-to-geolocation
            setResults((prev) => {
                const updatedResults = { ...prev };
                updatedResults['ip-to-geolocation'] = null;
                return updatedResults;
            });
            return;
        }
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/ipToScore`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({username, token, ip})
            });
            if(!response.ok) {
                throw new Error('Failed to fetch IP to score.');
            }
            const data = await response.json();
            var value = data.data;
            if(value) {
                setAlert((prev) => {
                    const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-to-score'); // Remove existing alert for the same tool
                    return [
                        ...updatedAlerts,
                        {
                            type: 'success',
                            tool: 'ip-to-score',
                            message: 'IP to score fetched successfully.',
                            duration: 5000
                        }
                    ];
                });
                setResults((prev) => {
                    const updatedResults = { ...prev };
                    updatedResults['ip-to-score'] = value; // Store result with tool ID
                    return updatedResults;
                });
            } else {
                setAlert((prev) => {
                    const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-to-score'); // Remove existing alert for the same tool
                    return [
                        ...updatedAlerts,
                        {
                            type: 'danger',
                            tool: 'ip-to-score',
                            message: 'An error occurred while fetching the IP to score.',
                            duration: 5000
                        }
                    ];
                });
            }
            return value;
        } catch (error) {
            console.error(error);
            setAlert((prev) => {
                const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-to-score'); // Remove existing alert for the same tool
                return [
                    ...updatedAlerts,
                    {
                        type: 'danger',
                        tool: 'ip-to-score',
                        message: error.message || 'An error occurred while fetching the IP to score.',
                        duration: 5000
                    }
                ];
            });
        }
    }

    // IP is VPN
    const ipIsVPN = async (ip) => {
        if(!validateIP(ip)) {
            setAlert((prev) => {
                const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-is-vpn'); // Remove existing alert for the same tool
                return [
                    ...updatedAlerts,
                    {
                        type: 'danger',
                        tool: 'ip-is-vpn',
                        message: 'Invalid IP address.',
                        duration: 5000
                    }
                ];
            });
            // Reset the results for ip-is-vpn
            setResults((prev) => {
                const updatedResults = { ...prev };
                updatedResults['ip-is-vpn'] = null;
                return updatedResults;
            });
            return;
        }
        try {
            const response = await fetch(`${config.apiUrl}/fpanel/user/ipIsVPN`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({username, token, ip})
            });
            if(!response.ok) {
                throw new Error('Failed to fetch IP to score.');
            }
            const data = await response.json();
            var value = data.data;
            if(value) {
                setAlert((prev) => {
                    const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-is-vpn'); // Remove existing alert for the same tool
                    return [
                        ...updatedAlerts,
                        {
                            type: 'success',
                            tool: 'ip-is-vpn',
                            message: 'IP is VPN fetched successfully.',
                            duration: 5000
                        }
                    ];
                });
                setResults((prev) => {
                    const updatedResults = { ...prev };
                    updatedResults['ip-is-vpn'] = value; // Store result with tool ID
                    return updatedResults;
                });
            } else {
                setAlert((prev) => {
                    const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-is-vpn'); // Remove existing alert for the same tool
                    return [
                        ...updatedAlerts,
                        {
                            type: 'danger',
                            tool: 'ip-is-vpn',
                            message: 'An error occurred while fetching the IP is VPN.',
                            duration: 5000
                        }
                    ];
                });
            }
            return value;
        } catch (error) {
            console.error(error);
            setAlert((prev) => {
                const updatedAlerts = prev.filter(alert => alert.tool !== 'ip-is-vpn'); // Remove existing alert for the same tool
                return [
                    ...updatedAlerts,
                    {
                        type: 'danger',
                        tool: 'ip-is-vpn',
                        message: error.message || 'An error occurred while fetching the IP is VPN.',
                        duration: 5000
                    }
                ];
            });
        }
    }

    useEffect(() => {
        if(alert.length > 0) {
            console.log(`alerts`, alert);
        }
    }, [alert]);

	return (
		<div className="wrapper">
			<Menu />
			<div className="main">
				<TopBar />
				<main className="content">
					<div className="container-fluid p-0">
						<div className="d-flex justify-content-between align-items-center mb-3">
							<h1 className="h3">Tools</h1>
						</div>

						{/* Tool links */}
						<div className="row mb-4">
							<div className="col-12">
								<h5 className="card-title mb-2">External Links</h5>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table">
												<thead>
													<tr>
                                                        <th>Name</th>
														<th>Chains</th>
														<th>Category</th>
                                                        <th>Popularity / 5</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													<tr>
                                                        <td style={{minWidth: '200px'}}><img src='/icons/external/openocean.png' alt='OpenOcean' className='me-2 rounded-circle' style={{width: '30px', height: '30px'}} /> OpenOcean</td>
														<td style={{minWidth: '200px'}}>ETH, BSC, POLYGON, ARBITRUM, OPTIMISM, BASE, + More</td>
														<td style={{minWidth: '200px'}}>Token Exchange</td>
                                                        <td style={{minWidth: '200px'}}>
                                                            {Array.from({ length: 3 }, (_, index) => (
                                                                <span key={index} role="img" aria-label="fire">🔥</span>
                                                            ))}
                                                        </td>
														<td style={{minWidth: '200px'}}>
															<a
																href="https://widget.openocean.finance"
																className="btn btn-primary"
																target="_blank"
																rel="noopener noreferrer"
															>
																Go to Site <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
															</a>
														</td>
													</tr>
													<tr>
                                                        <td style={{minWidth: '200px'}}><img src='/icons/external/uniswap.png' alt='Uniswap' className='me-2 rounded-circle' style={{width: '30px', height: '30px'}} /> Uniswap</td>
														<td style={{minWidth: '200px'}}>ETH, BSC, POLYGON, ARBITRUM, OPTIMISM, BASE, + More</td>
														<td style={{minWidth: '200px'}}>Token Exchange</td>
                                                        <td style={{minWidth: '200px'}}>
                                                            {Array.from({ length: 5 }, (_, index) => (
                                                                <span key={index} role="img" aria-label="fire">🔥</span>
                                                            ))}
                                                        </td>
														<td style={{minWidth: '200px'}}>
															<a
																href="https://app.uniswap.org"
																className="btn btn-primary"
																target="_blank"
																rel="noopener noreferrer"
															>
																Go to Site <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
															</a>
														</td>
													</tr>
													<tr>
                                                        <td style={{minWidth: '200px'}}><img src='/icons/external/blur.png' alt='Blur' className='me-2 rounded-circle' style={{width: '30px', height: '30px'}} /> Blur</td>
														<td style={{minWidth: '200px'}}>ETH, BLAST</td>
														<td style={{minWidth: '200px'}}>NFT Exchange</td>
                                                        <td style={{minWidth: '200px'}}>
                                                            {Array.from({ length: 4 }, (_, index) => (
                                                                <span key={index} role="img" aria-label="fire">🔥</span>
                                                            ))}
                                                        </td>
														<td style={{minWidth: '200px'}}>
															<a
																href="https://blur.io"
																className="btn btn-primary"
																target="_blank"
																rel="noopener noreferrer"
															>
																Go to Site <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
															</a>
														</td>
													</tr>
													<tr>
                                                        <td style={{minWidth: '200px'}}><img src='/icons/external/opensea.png' alt='Opensea' className='me-2 rounded-circle' style={{width: '30px', height: '30px'}} /> Opensea</td>
														<td style={{minWidth: '200px'}}>ETH, BSC, POLYGON, ARBITRUM, OPTIMISM, BASE, + More</td>
														<td style={{minWidth: '200px'}}>NFT Exchange</td>
                                                        <td style={{minWidth: '200px'}}>
                                                            {Array.from({ length: 5 }, (_, index) => (
                                                                <span key={index} role="img" aria-label="fire">🔥</span>
                                                            ))}
                                                        </td>
														<td style={{minWidth: '200px'}}>
															<a
																href="https://opensea.io"
																className="btn btn-primary"
																target="_blank"
																rel="noopener noreferrer"
															>
																Go to Site <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
															</a>
														</td>
													</tr>
													<tr>
                                                        <td style={{minWidth: '200px'}}><img src='/icons/external/magiceden.png' alt='MagicEden' className='me-2 rounded-circle' style={{width: '30px', height: '30px'}} /> MagicEden</td>
														<td style={{minWidth: '200px'}}>ETH, BTC, BASE, ARBITRUM, SOLANA, + More</td>
														<td style={{minWidth: '200px'}}>NFT Exchange</td>
                                                        <td style={{minWidth: '200px'}}>
                                                            {Array.from({ length: 2 }, (_, index) => (
                                                                <span key={index} role="img" aria-label="fire">🔥</span>
                                                            ))}
                                                        </td>
														<td style={{minWidth: '200px'}}>
															<a
																href="https://magiceden.io"
																className="btn btn-primary"
																target="_blank"
																rel="noopener noreferrer"
															>
																Go to Site <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
															</a>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Tools */}
						<div className="row mb-4">

							<div className="col-12">
								<h5 className="card-title mb-2">TargetX Tools</h5>
								<div className="card">
									<div className="card-body">

                                        <div className='row'>

                                            {/* IP to geolocation */}
                                            <div className='col-md-4 col-sm-12 col-lg-4'>
                                                <div className='card bg-light mb-0'>
                                                    <div className='card-body'>
                                                        <h6 className='card-title mb-2'>IP to Geolocation</h6>
                                                        <p>Given an IP, it will work out the geolocation country the IP is from. If ip is not valid, it will return an error.</p>
                                                        <div className='form-group mt-3'>
                                                            <input type='text' className='form-control' id="ip-to-geolocation" placeholder='Enter IP address' />

                                                            {alert.find(a => a.tool === 'ip-to-geolocation') && (
                                                                <div className={`alert alert-${alert.find(a => a.tool === 'ip-to-geolocation').type} mt-2 mb-0`} role="alert">
                                                                    <div className="alert-message">
                                                                        <strong>{alert.find(a => a.tool === 'ip-to-geolocation').type === 'danger' ? 'Error!' : 'Success!'}</strong>
                                                                        <br />
                                                                        {alert.find(a => a.tool === 'ip-to-geolocation').message}
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {/* Display the result for IP to Geolocation */}
                                                            {results['ip-to-geolocation'] && (
                                                                <div className="result mt-3">
                                                                    <strong>Geolocation Result:</strong> {results['ip-to-geolocation']}
                                                                </div>
                                                            )}

                                                            <button className='btn btn-primary mt-3 w-100' onClick={() => getIPToGeolocation(document.getElementById('ip-to-geolocation').value)}>Get Geolocation</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* IP to score */}
                                            <div className='col-md-4 col-sm-12 col-lg-4'>
                                                <div className='card bg-light mb-0'>
                                                    <div className='card-body'>
                                                        <h6 className='card-title mb-2'>IP to score</h6>
                                                        <p>Given an IP, it will work out the score of the IP. 1 being banned (a web host, VPN, or TOR node) 0 being a normal IP.</p>
                                                        <div className='form-group mt-3'>
                                                            <input type='text' className='form-control' id="ip-to-score" placeholder='Enter IP address' />

                                                            {alert.find(a => a.tool === 'ip-to-score') && (
                                                                <div className={`alert alert-${alert.find(a => a.tool === 'ip-to-score').type} mt-2 mb-0`} role="alert">
                                                                    <div className="alert-message">
                                                                        <strong>{alert.find(a => a.tool === 'ip-to-score').type === 'danger' ? 'Error!' : 'Success!'}</strong>
                                                                        <br />
                                                                        {alert.find(a => a.tool === 'ip-to-score').message}
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {/* Display the result for IP to Geolocation */}
                                                            {results['ip-to-score'] && (
                                                                <div className="result mt-3">
                                                                    <strong>Score Result:</strong> {results['ip-to-score']} / 100 (0 is good, 100 is bad (risk))
                                                                </div>
                                                            )}

                                                            <button className='btn btn-primary mt-3 w-100' onClick={() => ipToScore(document.getElementById('ip-to-score').value)}>Get score</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* IP to geolocation */}
                                            <div className='col-md-4 col-sm-12 col-lg-4'>
                                                <div className='card bg-light mb-0'>
                                                    <div className='card-body'>
                                                        <h6 className='card-title mb-2'>IP is VPN</h6>
                                                        <p>Given an IP, it will work out if the IP is a VPN or not. If ip is not valid, it will return an error.</p>
                                                        <div className='form-group mt-3'>
                                                            <input type='text' className='form-control' id="ip-is-vpn" placeholder='Enter IP address' />

                                                            {alert.find(a => a.tool === 'ip-is-vpn') && (
                                                                <div className={`alert alert-${alert.find(a => a.tool === 'ip-is-vpn').type} mt-2 mb-0`} role="alert">
                                                                    <div className="alert-message">
                                                                        <strong>{alert.find(a => a.tool === 'ip-is-vpn').type === 'danger' ? 'Error!' : 'Success!'}</strong>
                                                                        <br />
                                                                        {alert.find(a => a.tool === 'ip-is-vpn').message}
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {/* Display the result for IP to Geolocation */}
                                                            {results['ip-is-vpn'] && (
                                                                <div className="result mt-3">
                                                                    <strong>VPN Result:</strong> {results['ip-is-vpn'] == true ? <span className='text-success'><i className="fa-solid fa-check"></i> Yes</span> : <span className='text-danger'><i className="fa-solid fa-xmark"></i> No</span>}
                                                                </div>
                                                            )}

                                                            <button className='btn btn-primary mt-3 w-100' onClick={() => ipIsVPN(document.getElementById('ip-is-vpn').value)}>Check VPN</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

									</div>
								</div>
							</div>
                            
						</div>

					</div>
				</main>
			</div>
		</div>
	);
}
