import React, { useState } from "react";

const DesignSelector = ({ connectionType, designs, searchTerm, selectedDesign, onSearchChange, onDesignSelect, onDesignUpdate, setShowPopupForCustomDesign }) => {
	const [zipFile, setZipFile] = useState(null);

	// We don't allow cdn sites to select designs as it doesn't work
	if (connectionType !== "nameservers") {
		return null;
	}

	const handleFileChange = (event) => {
		setZipFile(event.target.files[0]);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (selectedDesign.toLowerCase() === "custom" && zipFile) {
			onDesignUpdate(zipFile);
		} else {
			onDesignUpdate();
		}
	};

	return (
		<div className="col-12 col-xl-12 col-sm-12 col-lg-12">
			<h3>Change Design</h3>
			<div className="card">
				<div className="card-header">
					<h5 className="mb-0">
						<a
							href="#"
							data-bs-toggle="collapse"
							data-bs-target="#collapseOne"
							aria-expanded="false"
							aria-controls="collapseOne"
							className="collapsed btn btn-primary w-100"
						>
							<i className="fas fa-pencil-alt"></i> Edit
						</a>
					</h5>
				</div>
				<div
					className="card-body collapse"
					id="collapseOne"
				>
					<div
						className="alert alert-primary alert-outline-coloured alert-dismissible"
						role="alert"
					>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="alert"
							aria-label="Close"
						/>
						<div className="alert-icon">
							<i className="fas fa-fw fa-exclamation-circle" />
						</div>
						<div className="alert-message">You can scroll down inside the below section to find your design</div>
					</div>
					<form className="mb-4">
						<input
							type="text"
							className="form-control"
							placeholder="Search for a design"
							value={searchTerm}
							onChange={onSearchChange}
						/>
					</form>
					<form
						className="mb-4"
						onSubmit={handleSubmit}
					>
						<div
							className="row"
							style={{ overflowY: "scroll", maxHeight: "500px" }}
						>
							{designs
								.filter((design) => design.name.toLowerCase().includes(searchTerm.toLowerCase()))
								.map((design) => (
									<div
										className="col-md-6 col-sm-2 col-lg-3"
										key={design.id}
									>
										<div
											className={`card ${selectedDesign.toLowerCase() === design.name.toLowerCase() ? "opacity-100" : "opacity-50"} w-100`}
											style={
												design.previewImg
													? {
															minHeight: "200px",
															backgroundPosition: "center",
															backgroundImage: `url(${design.previewImg})`,
															backgroundSize: "cover",
															transform: selectedDesign.toLowerCase() === design.name.toLowerCase() ? "scale(1.05)" : "scale(1)",
															transition: "transform 0.3s ease",
													  }
													: {
															backgroundColor: "#19222C",
															transform: selectedDesign.toLowerCase() === design.name.toLowerCase() ? "scale(1.1)" : "scale(1)",
															transition: "transform 0.3s ease",
													  }
											}
											onClick={() => onDesignSelect(design.name.toLowerCase())}
										>
											<div className="card-body">
												<h5 className="badge bg-primary">{design.name}</h5>
											</div>
										</div>
									</div>
								))}
						</div>
						{selectedDesign.toLowerCase() === "custom" && (
							<>
								<div className="form-control mt-3">
									<input
										id="zipFileInput"
										type="file"
										accept=".zip"
										onChange={handleFileChange}
									/>
									<label htmlFor="zipFileInput">Choose a .zip file</label>
								</div>
								<div
									className="alert alert-warning alert-dismissible mt-3"
									role="alert"
								>
									<button
										type="button"
										className="btn-close"
										data-bs-dismiss="alert"
										aria-label="Close"
									/>
									<div className="alert-message">
										<strong>Things to keep in mind!</strong>
										<br />
										<ul>
											<li>Only .html, .css and .js files are allowed</li>
											<li>The .zip file must contain only one .html file</li>
											<li>Max file size is 200MB per file</li>
											<li>Max files is 100 files (can be any of the allowed file types above)</li>
											<li>You can add directories, it will loop through all the files in the directory</li>
											<li>Don't use encrypted js or html as will add extreme overhead to your file size</li>
										</ul>
									</div>
								</div>
							</>
						)}
						<button
							type="submit"
							className="btn btn-primary w-100 d-block mt-3"
						>
							<i className="fas fa-fw fa-check" /> Save Design
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default DesignSelector;
